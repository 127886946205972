import TMap from 'TMap'

// 自定义HTML元素 - 继承DOMOverlay
function TMapStaticMaker(options) {
	TMap.DOMOverlay.call(this, options);
}

TMapStaticMaker.prototype = new TMap.DOMOverlay();

// 初始化
TMapStaticMaker.prototype.onInit = function(options) {
	this.position = options.position
  this.content = options.content
}

// 销毁时需解绑事件监听
TMapStaticMaker.prototype.onDestroy = function() {
	if (this.onClick) {
		// this.dom.removeEventListener('click', this.onClick)
	}
}

// 创建DOM元素，返回一个DOMElement，使用this.dom可以获取到这个元素
TMapStaticMaker.prototype.createDOM = function() {
	let div = document.createElement('div');
  // 设置绝对定位，才不会漂移
  div.setAttribute('style', 'position:absolute;top:0px;left:0px;')
  div.innerHTML = this.content
  // click事件监听
  this.onClick = () => {
	  // DOMOverlay继承自EventEmitter，可以使用emit触发事件
	  // this.emit('click')
	}
	// pc端注册click事件，移动端注册touchend事件
  // div.addEventListener('click', this.onClick)
	return div
}

// 更新DOM元素，在地图移动/缩放后执行
TMapStaticMaker.prototype.updateDOM = function() {
	if (!this.map) {
		return;
	}

	// 经纬度坐标转容器像素坐标
	let pixel = this.map.projectToContainer(this.position);

	// 使中心点对齐经纬度坐标点
  // 以下实现存在问题
	// let left = (pixel.getX() +  this.dom.clientWidth / 2) + 'px';
	// let top = (pixel.getY() + this.dom.clientHeight / 2) + 'px';

	let left = (pixel.getX() - 10) + 'px' // 高度/2
	let top = (pixel.getY() - 10) + 'px' // 宽度/2

	this.dom.style.transform = `translate(${left}, ${top})`;
};

export default TMapStaticMaker