import TMap from 'TMap'

// 自定义HTML元素 - 继承DOMOverlay
function TMapFlashMaker(options) {
	TMap.DOMOverlay.call(this, options);
}

TMapFlashMaker.prototype = new TMap.DOMOverlay();

// 初始化
TMapFlashMaker.prototype.onInit = function(options) {
	this.position = options.position
  this.content = options.content
}

// 销毁时需解绑事件监听
TMapFlashMaker.prototype.onDestroy = function() {
	if (this.onClick) {
		// this.dom.removeEventListener('click', this.onClick)
	}
}

// 创建DOM元素，返回一个DOMElement，使用this.dom可以获取到这个元素
TMapFlashMaker.prototype.createDOM = function() {
	let div = document.createElement('div');
  div.setAttribute('style', 'position:absolute;top:0px;left:0px;')
  div.innerHTML = this.content
  // click事件监听
  this.onClick = () => {
	  // DOMOverlay继承自EventEmitter，可以使用emit触发事件
	  // this.emit('click')
	}
	// pc端注册click事件，移动端注册touchend事件
  // div.addEventListener('click', this.onClick)
	return div
}

// 更新DOM元素，在地图移动/缩放后执行
TMapFlashMaker.prototype.updateDOM = function() {
	if (!this.map) {
		return;
	}

	// 经纬度坐标转容器像素坐标
	let pixel = this.map.projectToContainer(this.position);

	// 使饼图中心点对齐经纬度坐标点
	// let left = (pixel.getX() +  this.dom.offsetWidth / 2) + 'px';
	// let top = (pixel.getY() + this.dom.offsetHeight / 2) + 'px';

	let left = (pixel.getX() - 16) + 'px';
	let top = (pixel.getY() - 16) + 'px';

	this.dom.style.transform = `translate(${left}, ${top})`;
};

export default TMapFlashMaker