<template>
  <div class="app">
    <div id="map" :class="mapClass"></div>
    <div class="back" @click="goBack" style="z-index: 99999">
      <i class="icon left-link"></i
      ><span class="search-station-name">{{
        busLine ? busLine.name : "未知"
      }}</span>
    </div>

    <div class="route-panel" :class="panelClass">
      <div class="map-control">
        <!--
				<div class="full-view">
					<i class="icon"></i>
					<div class="text">全览</div>
				</div>
				<div class="location">
					<i class="icon"></i>
				</div>
        -->
      </div>
      <div id="panel" @touchstart="touchstart" @touchmove="touchmove">
        <div class="finger-line"></div>
        <div class="destination-box">
          <div class="destination">
            <div class="start-end" style="font-size: 16px">
              <span class="start">{{ busLine.fromStation || "未知" }}</span>
              <span class="icon arrow"></span>
              <span class="end">{{ busLine.toStation || "未知" }}</span>
            </div>
            <div class="fares">
              <span class="text">
                首班 {{ firstDeparture }} &nbsp;&nbsp;&nbsp;&nbsp; 末班{{
                  lastDeparture
                }}
              </span>
              <span class="time-table" @click="timetable">
                <i class="icon"></i>
                <span>时刻表</span>
              </span>
            </div>
          </div>
          <!--
					<div class="inverting">
						<div class="icon"></div>
						<p>换向</p>
					</div>
          -->
        </div>
        <div class="current-time-remaining">
          <div
            v-if="!waitingInfo || !waitingInfo.busOperating"
            class="time-remaining"
            style="font-size: 16px"
          >
            <div>非运营时间</div>
            <div v-if="waitingInfo.distanceToMine">
              {{ formatDistanceToMine(waitingInfo.distanceToMine) }}
            </div>
          </div>
          <div
            v-else-if="waitingInfo.busArrived"
            class="time-remaining"
            style="font-size: 16px"
          >
            <div>已到站</div>
            <div v-if="waitingInfo.distanceToMine">
              {{ formatDistanceToMine(waitingInfo.distanceToMine) }}
            </div>
          </div>
          <div
            v-else-if="waitingInfo.busMissed"
            class="time-remaining"
            style="font-size: 16px"
          >
            <div>等待{{ waitingInfo.nextDepartureTime || "" }}发车</div>
            <div v-if="waitingInfo.distanceToMine">
              {{ formatDistanceToMine(waitingInfo.distanceToMine) }}
            </div>
          </div>
          <div v-else class="time-remaining">
            <div class="time">
              <template v-if="waitingInfo.waitingSeconds <= 60">
                <span class="num">1分钟内</span>
              </template>
              <template v-else>
                <span class="num">{{
                  Math.round(waitingInfo.waitingSeconds / 60)
                }}</span
                ><span class="unit">分钟</span>
              </template>
            </div>
            <div class="distance">
              {{ waitingInfo.waitingStations }}站 /
              {{ formatDistance(waitingInfo.waitingDistance) }}&nbsp;&nbsp;
              <template v-if="waitingInfo.distanceToMine">{{
                formatDistanceToMine(waitingInfo.distanceToMine)
              }}</template>
            </div>
          </div>
          <!--
					<div class="more-vehicle">
						<div class="icon"></div>
						<div class="right-link">更多车辆</div>
					</div>
          -->
        </div>
      </div>
      <div class="route-station">
        <div
          class="station"
          v-for="station in busLine.stations"
          :class="{ current: station.id === waitingOptions.busStationId }"
          :key="station.id"
          @click="waitBus(busLine.id, busLine.direction, station.id)"
        >
          <div class="point"></div>
          <div class="line"></div>
          <div class="name">
            {{ station.order + " " + station.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="operation">
      <!--
			<div class="tab collect">
				<div class="icon icon-collect"></div>
				<div class="text">收藏</div>
			</div>
			<div class="tab record">
				<div class="icon icon-collect"></div>
				<div class="text">疫情打卡</div>
			</div>
      -->
      <div class="tab route" @click="sameLine">
        <div class="icon icon-collect"></div>
        <div class="text">同站线路</div>
      </div>
      <div class="tab route_2">
        <div class="icon icon-collect"></div>
        <div class="text" @click="allLine">全部线路</div>
      </div>
      <button class="btn" @click="locateWX">
        <!--<i class="icon refresh"></i>-->
        <span>刷新</span>
      </button>
    </div>
  </div>
</template>
<script>
import {
  connectBusHub,
  getBusLine,
  setLocation,
  waitBus,
  getBusLineDeparture,
} from "../../signalR/busHub";
// import { requestLocation } from "../../amap/index.js.bak";
/* eslint-disable-next-line */
import {
  loadTMap,
  createRouteMap,
  updateRouteLayer,
  updateStationLayer,
  computeDistance,
} from "../../tmap/index";
import { wxAutoReady, wxLocate } from "@/utils/api";

// eslint-disable-next-line
import { mapGetters, mapState } from "vuex";

import buspng from "../../assets/line/bus.png";
import locationPNG from "../../assets/line/location.png";
import "../../assets/css/index.less";

export default {
  data() {
    return {
      panelClass: "height1",
      mapClass: "height1",
      currentStation: 1,

      busLine: {},
      stationListVisible: false,

      firstDeparture: "无",
      lastDeparture: "无",

      siteId: "",
      // siteName: "",
      busLineId: 1,
      direction: 1,

      // pay: 1,
      // time_s: "",
      // time_e: "",
      // waitingInfo: {},
      curSize_location: null,
      listWidth: "",
      ind: 0,
      map: null,
      startMarker: null,
      endMarker: null,
      curSiteMarker: null,
      city: this.$store.state.currentCity,
      buspng: buspng,
      locationPNG: locationPNG,
    };
  },
  computed: {
    ...mapState(["waitingInfo", "waitingOptions"]),
    ...mapGetters(["getWaitingInfo"]),
  },
  watch: {
    // eslint-disable-next-line
    waitingInfo(val) {
      // updateRouteLayer(val ? { Latitude: val.busLatitude, Longitude: val.busLongitude } : null)
    },
    // getWaitingInfo(oldVal, newVal) {
    // getWaitingInfo(newVal, oldVal) {
    getWaitingInfo(newVal) {
      // console.log('getWaitingInfo watch')
      // console.log(oldVal)
      // console.log(newVal)
      const val = newVal;

      this.renderWaitingInfo(val)

      // this.carMove.last = this.carMove.current;
      // this.carMove.current = val
      //   ? {
      //       Latitude: val.busLatitude,
      //       Longitude: val.busLongitude,
      //       Angle: val.angle,
      //     }
      //   : null;
      // this.carMove.progress =
      //   val.waitingStations + "站，" + this.formatDistance(val.waitingDistance);
      // this.carMove.state =
      //   !val || !val.busOperating
      //     ? "非运营时间"
      //     : val.busArrived
      //     ? "到站"
      //     : val.busMissed
      //     ? "等待" + (val.nextDepartureTime || "") + "发车"
      //     : val.waitingSeconds <= 60
      //     ? "预计1分钟内到达"
      //     : "预计" + Math.round(val.waitingSeconds / 60) + "分钟到达";
      // if (this.carMove.state.startsWith("预计") == false) {
      //   this.carMove.progress = null;
      // }
      // // console.log(this.carMove)
      // this.renderBusLine(this.carMove);
    },
  },
  created: function () {
    // console.log('txMap.created')

    const query = this.$route.query;
    this.busLineId = Number(query.busLineId);
    this.direction = Number(query.direction);
    this.siteId = Number(query.siteId);
    // this.siteName = query.siteName
    this.callapsingTimer = null;

    this.carMove = {
      last: null,
      current: null,
      // mine: { Longitude: 116.32707, Latitude: 40.04593 }
      // mine: { Longitude: 116.6240666, Latitude: 23.6565324 }
      mine: { Longitude: null, Latitude: null },
    };
  },
  mounted() {
    this.initMap();
    // this.initFinger()
  },
  methods: {
    renderWaitingInfo (val) {
      // console.log('renderWaitingInfo')
      // console.log(val)
      const current = val ? {
        Latitude: val.busLatitude,
        Longitude: val.busLongitude,
        Angle: val.angle,
      } : null

      const last = this.carMove.current
      let movingDistance = 0
      if (last != null && last.Latitude > 0 && current != null && current.Latitude > 0) {
        movingDistance = computeDistance(this.map, last.Latitude, last.Longitude, current.Latitude, current.Longitude)
        if (movingDistance <= 5) {
          // console.log(`移动幅度${movingDistance}过小，不更新车辆`)
          return // 移动
        }
      }

      this.carMove.last = this.carMove.current;
      this.carMove.current = current
      this.carMove.progress =
        val.waitingStations + "站，" + this.formatDistance(val.waitingDistance);
      this.carMove.state =
        !val || !val.busOperating
          ? "非运营时间"
          : val.busArrived
          ? "到站"
          : val.busMissed
          ? "等待" + (val.nextDepartureTime || "") + "发车"
          : val.waitingSeconds <= 60
          ? "预计1分钟内到达"
          : "预计" + Math.round(val.waitingSeconds / 60) + "分钟到达";
      if (this.carMove.state.startsWith("预计") == false) {
        this.carMove.progress = null;
      }
      this.renderBusLine(this.carMove);
    },
    formatDistance(distance) {
      if (distance == null || distance == undefined) {
        return "";
      }

      if (distance > 1000) {
        return (distance / 1000).toFixed(1) + "公里";
      } else if (distance < 20) {
        return "附近";
      } else if (distance < 100) {
        return "百米内";
      } else {
        return distance.toFixed(1) + "米";
      }
    },
    formatDistanceToMine(distance) {
      if (distance == null || distance == undefined) {
        return "";
      }

      if (distance > 1000) {
        return "站点离我" + (distance / 1000).toFixed(1) + "公里";
      } else if (distance <= 5) {
        return "在站点等候中";
      } else {
        return "站点离我" + distance.toFixed(1) + "米";
      }
    },
    goBack: function () {
      // console.log('goback')
      this.$router.push({
        path: "/",
        query: {},
      });
    },
    loadData() {
      this.getBusLine(this.busLineId, this.direction);
      // 首末班次信息已合并到公交线路信息，无需单独获取
      // this.getBusLineDeparture(this.busLineId, this.direction);
    },
    // 初始化地图
    initMap() {
      if (this.map) {
        return;
      }

      const that = this;
      loadTMap().then((TMap) => {
        // 设置地图中心点
        var latlon = new TMap.LatLng(23.6545, 116.62443);
        that.map = createRouteMap(TMap, "map", latlon);
        that.map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
        that.map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);
        that.loadData();

        that.callapsingTimer = setTimeout(() => {
          that.panelClass = "height2";
          that.mapClass = "height2";
        }, 2500);
      });
    },
    renderBusLine(carMove) {
      if (!this.map) return;

      // console.log('renderBusLine')

      // 绘制线路轨迹
      updateRouteLayer(this.map, carMove, this.tail);
    },
    // 获取当前线路数据
    getBusLine: function (busLineId, direction) {
      connectBusHub()
        .then((busHub) => {
          getBusLine(busHub, busLineId, direction)
            .then((res) => {
              // console.log('获取线路信息成功')
              // console.log(res)
              this.busLine = res;
              this.firstDeparture = this.busLine.firstDeparture;
              this.lastDeparture = this.busLine.lastDeparture;
              this.tail = (this.busLine.tail || "").split(";").map((latlon) => {
                const parts = latlon.split(",");
                return {
                  Latitude: Number(parts[0]),
                  Longitude: Number(parts[1]),
                };
              });

              // 后续优化
              this.listWidth = this.busLine.stations.length;

              updateStationLayer(this.map, this.busLine.stations);

              this.waitBus(this.busLineId, this.direction, this.siteId);
              // this.renderBusLine(this.carMove)

              this.lineName = res.name;
              this.from = res.fromStation;
              this.to = res.toStation;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 订阅对向线路
    invert() {
      this.direction = this.direction === 1 ? 2 : 1;
      this.getBusLine(this.busLineId, this.direction);
    },
    // 请求定位
    // locate() {
    //   requestLocation()
    //     .then((location) => {
    //       // this.location = location
    //       // console.log('成功完成定位')
    //       // console.log(location)
    //       this.setLocation(location.lat, location.lon);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       console.log("定位失败提示");
    //     });
    // },
    locateWX() {
      // console.log('locateWX')
      const that = this;
      that.firstLocating = false;

      wxAutoReady(null)
        .then(() => {
          wxLocate()
            .then((res) => {
              var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
              var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
              // var speed = res.speed; // 速度，以米/每秒计
              // var accuracy = res.accuracy; // 位置精度
              that.location = { lat: latitude, lon: longitude };
              that.carMove.mine = { Latitude: latitude, Longitude: longitude };
              console.log("成功完成定位");
              // console.log(this.location)
              that.setLocation(that.location.lat, that.location.lon);
              that.renderBusLine(this.carMove);
            })
            .catch((err) => {
              console.log(err);
              console.log("定位失败");
            });
        })
        .catch((err) => {
          console.log(err);
          console.log("定位失败");
        });
    },
    // 提交用户位置
    setLocation(latitude, longitude) {
      // 避免字符串类型导致调用失败
      latitude = Number(latitude) || null;
      longitude = Number(longitude) || null;
      connectBusHub()
        .then((busHub) => {
          setLocation(busHub, latitude, longitude)
            .then(() => {
              console.log("位置提交成功");
            })
            .catch((err) => {
              console.log(err);
              console.log("提交位置失败");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBusLineDeparture(busLineId, direction) {
      connectBusHub()
        .then((busHub) => {
          getBusLineDeparture(busHub, busLineId, direction)
            .then((res) => {
              // console.log(res)
              this.time_s = res.am[0];
              this.time_e = res.pm[res.pm.length - 1];
              console.log("获取发车时间表成功");
            })
            .catch((err) => {
              console.log(err);
              console.log("获取发车时间表失败");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 订阅线路
    waitBus(busLineId, direction, busStationId) {
      // console.log('waitBus')
      this.carMove = { current: null, last: null, mine: this.carMove.mine };
      this.$store.commit("setWaitingInfo", {});

      connectBusHub()
        .then((busHub) => {
          waitBus(busHub, busLineId, direction, busStationId)
            .then(() => {
              const options = { busLineId, direction, busStationId };
              this.$store.commit("waitBus", options);
              this.locateWX();
              this.carMove.waitingStation = !this.busLine
                ? null
                : this.busLine.stations.find((e) => e.id === busStationId);
              this.renderBusLine(this.carMove);
              // updateStationLayer(this.map, [ this.carMove.waitingStation ])
              // console.log(options)
              // console.log(res)
              console.log("订阅等候线路");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sameLine: function () {
      this.$router.push({
        path: "/sameLine",
        query: {
          siteId: this.siteId,
          // siteName: this.siteName,
        },
      });
    },
    allLine: function () {
      this.$router.push({
        path: "/",
        // query: {
        //   siteId: this.siteId,
        //   siteName: this.siteName,
        // }
      });
    },
    //时刻表
    timetable: function () {
      this.$router.push({
        path: "/timetable",
        query: {
          busLineId: this.busLineId,
          direction: this.direction,
        },
      });
    },
    touchstart(e) {
      // e.preventDefault();
      this.startX = e.changedTouches[0].pageX;
      this.startY = e.changedTouches[0].pageY;
    },
    touchmove(e) {
      if (this.callapsingTimer) {
        clearTimeout(this.callapsingTimer);
        this.callapsingTimer = 0;
      }

      e.preventDefault();
      let X, Y;
      const that = this;
      this.moveEndX = e.changedTouches[0].pageX;
      (this.moveEndY = e.changedTouches[0].pageY),
        (X = this.moveEndX - this.startX),
        (Y = this.moveEndY - this.startY);

      if (Math.abs(X) > Math.abs(Y) && X > 0) {
        // console.log("左往右滑");
      } else if (Math.abs(X) > Math.abs(Y) && X < 0) {
        // console.log("右往左滑");
      } else if (Math.abs(Y) > Math.abs(X) && Y > 0) {
        // console.log("上往下滑");
        that.panelClass = "height2";
        that.mapClass = "height2";
      } else if (Math.abs(Y) > Math.abs(X) && Y < 0) {
        // console.log("下往上滑");
        that.panelClass = "height1";
        that.mapClass = "height1";
      } else {
        // console.log("滑了个寂寞");
      }
    },
    initFinger() {
      const that = this;
      let startX, startY, moveEndX, moveEndY, X, Y;
      document
        .getElementById("panel")
        .addEventListener("touchstart", function (e) {
          e.preventDefault();
          startX = e.changedTouches[0].pageX;
          startY = e.changedTouches[0].pageY;
        });
      document
        .getElementById("panel")
        .addEventListener("touchmove", function (e) {
          e.preventDefault();
          moveEndX = e.changedTouches[0].pageX;
          (moveEndY = e.changedTouches[0].pageY),
            (X = moveEndX - startX),
            (Y = moveEndY - startY);

          if (Math.abs(X) > Math.abs(Y) && X > 0) {
            // console.log("左往右滑");
          } else if (Math.abs(X) > Math.abs(Y) && X < 0) {
            // console.log("右往左滑");
          } else if (Math.abs(Y) > Math.abs(X) && Y > 0) {
            // console.log("上往下滑");
            that.panelClass = "height2";
            that.mapClass = "height2";
          } else if (Math.abs(Y) > Math.abs(X) && Y < 0) {
            console.log("下往上滑");
            that.panelClass = "height1";
            that.mapClass = "height1";
          } else {
            console.log("滑了个寂寞");
          }
        });
    },
    handleStation(station) {
      this.currentStation = station;
    },
  },
};
</script>

<style>
.tmap_label {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  padding: 8px;
  height: 34px;
  line-height: 18px;
  width: 130px;
  text-align: center;
  color: #ffb31a;
  border-radius: 120px;
  font-size: 14px;
  background-color: #fff;
  display: inline-block;
}

.tmap_label p {
  font-size: 14px;
  color: #ff884d;
}

.flash {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
}

.flash .outer {
  box-sizing: border-box;
  height: 100%; /*关键所在，只能设置百分比，不能指定数值，内圆相同*/
  padding: 8px;
  background-color: rgba(9, 186, 7, 0.5);
  border-radius: 50%;
  animation-name: in;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.flash .inner {
  box-sizing: border-box;
  height: 100%;
  background-color: #09ba07;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.static {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
}

.static .outer {
  box-sizing: border-box;
  height: 100%; /*关键所在，只能设置百分比，不能指定数值，内圆相同*/
  border-radius: 50%;
  padding: 5px;
  background-color: rgba(242, 78, 78, 0.1);
}

.static .inner {
  box-sizing: border-box;
  height: 100%;
  background-color: #f24e4e;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

@keyframes in {
  25% {
    background-color: rgba(9, 186, 7, 0.5);
  }
  75% {
    background-color: rgba(9, 186, 7, 0.2);
  }
}
</style>
<style lang="less" scoped>
.app {
  height: 100%;
  line-height: 1.5;
}

#map {
  transition: height 0.5s;
  &.height1 {
    height: calc(100% - 490px);
  }
  &.height2 {
    height: calc(100% - 220px);
  }
}

.back {
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  padding: 0 12px;
  border-radius: 13px;
  position: fixed;
  top: 10px;
  left: 15px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  .icon {
    width: 16px;
    height: 16px;
  }
  .search-station-name {
  }
}

.map-control {
  position: absolute;
  right: 10px;
  top: -80px;
  text-align: center;
  .icon {
  }
  .full-view,
  .location {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .full-view {
    font-size: 8px;
    margin-bottom: 10px;
    .icon {
      width: 16px;
      height: 16px;
      background-image: url(../../assets/icons/full-view.svg);
    }
    .text {
      /*transform: scale(0.7);*/
      line-height: 1;
    }
  }
  .location {
    .icon {
      width: 20px;
      height: 20px;
      background-image: url(../../assets/icons/location.svg);
      background-image: url(../../assets/icons2/target-full.svg);
    }
  }
}

.icon {
  display: inline-block;
  background-size: cover;
  vertical-align: middle;
}

.route-panel {
  width: 100%;
  /*height: 70vh;*/
  border-radius: 12px 12px 0 0;
  background: #fff;
  position: absolute;
  z-index: 1500;
  transition: bottom 0.5s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  &.height1 {
    bottom: 58px;
  }
  &.height2 {
    bottom: -210px;
  }
  span {
    vertical-align: middle;
  }
  .finger-line {
    width: 50px;
    height: 4px;
    margin: 12px auto 0;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.destination-box {
  display: flex;
  justify-content: space-between;
  padding: 14px;
  /*margin-top: 12px;*/
  .destination {
    .star-end {
    }
    .arrow {
      width: 20px;
      height: 20px;
      background-image: url(../../assets/icons/left-switch.svg);
      background-image: url(../../assets/icons2/onward.svg);
      margin: 0 4px;
    }
    .fares {
      color: #666;
      font-size: 14px;
      .time-table {
        color: #3777ff;
        font-size: 14px;
        .icon {
          width: 14px;
          height: 14px;
          background-image: url(../../assets/icons/time-table.svg);
        }
      }
    }
  }
  .inverting {
    text-align: center;
    .icon {
      width: 20px;
      height: 20px;
      background-image: url(../../assets/icons/route-switch.svg);
    }
  }
}

.current-time-remaining {
  margin: 0 14px;
  height: 80px;
  display: flex;
  align-items: center;
  background: #f0f2f5 url(../../assets/images/card-bg1@3x.png) no-repeat;
  background-size: 100% auto;
  border-radius: 6px;
  text-align: center;
  .time-remaining {
    flex: 1;
    color: #3777ff;
    font-size: 12px;
    .time {
      height: 30px;
      .num {
        font-size: 22px;
      }
      .unit {
      }
    }
    .distance {
    }
  }
  .more-vehicle {
    width: 110px;
    color: #3777ff;
    font-size: 12px;
    line-height: 2;
    .icon {
      width: 14px;
      height: 14px;
      background-image: url(../../assets/icons/bus.svg);
    }
  }
}

&::-webkit-scrollbar {
  display: none;
}

.route-station {
  /*width: 100%;*/
  height: 280px;
  padding: 24px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  .station {
    position: relative;
    width: 67px;
    flex: 1 0 auto;
    max-width: 60px;
    &:last-child {
      /*margin-right: 24px;
				padding-right: 24px;*/
      width: 36px;
      .line {
        display: none;
      }
    }
    &.current {
      color: #3777ff;
      font-weight: bold;
      .point {
        border: 1px solid #fff;
        border: none;
        background: url(../../assets/icons/station.svg) no-repeat center / cover;
        transform: scale(1.5);
        position: relative;
        z-index: 5;
      }
      .name {
        margin-left: -4px;
      }
    }
    .point {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid green;
      background-color: #fff;
    }
    .line {
      width: 60px;
      height: 4px;
      background-color: green;
      position: absolute;
      left: 10px;
      top: 3px;
    }
    .name {
      width: 18px;
      text-align: center;
      margin-left: -4px;
      margin-top: 8px;
      line-height: 1.2;
    }
  }
}

.operation {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 1600;
  background: #fff;
  text-align: center;
  border-top: 1px solid #f0f2f5;
  padding: 0 8px;
  .tab {
    /*width: 15%;*/
    font-size: 12px;
    flex: 1;
  }
  .collect {
    .icon {
      width: 18px;
      height: 18px;
      background-image: url(../../assets/icons/collect.svg);
    }
  }
  .record {
    .icon {
      width: 18px;
      height: 18px;
      background-image: url(../../assets/icons/record.svg);
    }
  }
  .route {
    .icon {
      width: 20px;
      height: 20px;
      background-image: url(../../assets/icons/route.svg);
      background-image: url(../../assets/icons2/locus.svg);
    }
  }
  .route_2 {
    .icon {
      width: 20px;
      height: 20px;
      background-image: url(../../assets/icons2/orbit.svg);
    }
  }
  .btn {
    width: 150px;
    height: 42px;
    margin-left: 8px;
    border-radius: 30px;
    background: rgba(66, 170, 255, 0.1);
    border: none;
    color: #3777ff;
    .icon {
      width: 18px;
      height: 18px;
      background-image: url(../../assets/icons/refresh.svg);
      background-image: url(../../assets/icons2/refresh.svg);
      margin-right: 4px;
    }
  }
}
</style>
