import TMapDIVMaker from './custom'
import TMapFlashMaker from './flashpoint'
import TMapStaticMarker from './staticpoint'

export function loadTMap () {
  return new Promise(function (resolve, reject) {
    if (window.TMap) {
      resolve(window.TMap)
    } else {
      window.tmap_osharp_init = function () {
        resolve(window.TMap)
      }

      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://map.qq.com/api/gljs?v=1.exp&callback=tmap_osharp_init&key=CPHBZ-7ZBK5-6OUI6-Q2MJC-GGEMZ-QKFEV&libraries=geometry'
      script.onerror = reject
      document.head.appendChild(script)
    }
  })
}

export function createRouteMap (TMap, id, latlon) {
  // 定义工厂模式函数
  var options = {
    rotation: 20, // 设置地图旋转角度
    // pitch: 30, // 设置俯仰角度（0~45）
    pitch: 0, // 设置俯仰角度（0~45）
    zoom: 16, // 设置地图缩放级别
    center: latlon, // 设置中心点样式,
    viewMode: '2D' // 禁止倾斜
  }
  // 获取dom元素添加地图信息
  var map = new TMap.Map(document.getElementById(id), options)
  map.cfg = {
    TMap
  }

  // 创建图层并关联到 map 对象
  createStationLayer(TMap, map)
  createRouteLayer(TMap, map)

  // 初始化infoWindow
  const infoWindow = new TMap.InfoWindow({
    map: map,
    position: new TMap.LatLng(0, 0),
    offset: { x: 0, y: -50 } // 设置信息窗相对position偏移像素
  })
  // 初始关闭信息窗关闭
  infoWindow.close()
  map.cfg.infoWindow = infoWindow

  return map
}

export function clearRouteMap (map) {
  if (!map || !map.cfg) {
    return
  }

  [map.cfg.stationLayer, map.cfg.routeLayer, map.cfg.routePolylineLayer].forEach((layer) => {
    layer.remove(layer.getGeometries().map((e) => e.id))
  })
}

// 创建站点图层
export function createStationLayer (TMap, map) {
  map.cfg.stationLayer = new TMap.MultiMarker({
    id: 'BusLineRoute-station-layer',
    map: map,
    styles: {
      'station': new TMap.MarkerStyle({
        // 'width': 20,
        // 'height': 20,
        'anchor': {
          x: 10,
          y: 10
        },
        'faceTo': 'map',
        // 'rotate': 180
        'src': '/images/icons/station.svg'
      })
    }
  })
  map.cfg.stationLayer.on('click', function (evt) {
    // console.log(evt)
    const infoWindow = map.cfg.infoWindow
    if (evt.geometry) {
      const station = evt.geometry
      // 设置infoWindow
      infoWindow.open() // 打开信息窗
      infoWindow.setPosition(station.position) // 设置信息窗位置
      // 设置信息窗内容
      infoWindow.setContent(`第${station.stationOrder}站： ${station.stationName}`)
      infoWindow.setContent(`<div style='margin: 10px;'><p style='font-weight: bold;'>第${station.stationOrder}站： ${station.stationName}</p></div>`)
    }
  })
}

export function updateStationLayer (map, stations) {
  if (!map.cfg || !map.cfg.stationLayer) {
    return
  }

  // console.log('updateStationLayer')
  // console.log(stations)

  const TMap = map.cfg.TMap
  const layer = map.cfg.stationLayer
  layer.remove(layer.getGeometries().map((e) => e.id))
  if (!stations || stations.length === 0) {
    return
  }

  // console.log('updateStationLayer')
  // console.log(layer)

  // layer.add(stations.map((e) => {
  //   // console.log(e)
  //   return {
  //     'id': e.id.toString(),
  //     'stationName': e.Name,
  //     'stationOrder': e.order,
  //     'position': new TMap.LatLng(e.location.lat, e.location.lon)
  //   }
  // }))

  let markers = map.cfg.stationMarkers || []
  markers.forEach(e => e.destroy())
  markers = []

  markers = stations.map(e => {
    let marker = new TMapStaticMarker({
      map: map,
      content:
        '<div class="static"><div class="outer"><div class="inner"></div></div></div>',
        position: new TMap.LatLng(e.location.lat, e.location.lon)
    })
    // console.log(marker)
    return marker
  })

  map.cfg.stationMarkers = markers

  // const path = stations.map((e) => new TMap.LatLng(e.Latitude, e.Longitude))
  // 调整中心点
  // const centroid = TMap.geometry.computeCentroid(path)
  // map.setCenter(centroid)

  // 调整地图可视化区域，完全显示所有点
  // 此效果优于 setCenter
  // const bounds = computeBounds(map, path)
  // map.fitBounds(bounds, { padding: 40 })
}

// 创建线路轨迹图层
export function createRouteLayer (TMap, map) {
  map.cfg.routeLayer = new TMap.MultiMarker({
    id: 'BusLineRoute-route-layer',
    map: map,
    styles: {
      'station': new TMap.MarkerStyle({
        // 'width': 20,
        // 'height': 20,
        'anchor': {
          x: 17,
          y: 45
        },
        'faceTo': 'map',
        // 'rotate': 180
        // 'src': '/images/icons/station.svg'
      }),
      'car-down': new TMap.MarkerStyle({
        'width': 40,
        'height': 40,
        'anchor': {
          x: 20,
          y: 20
        },
        'faceTo': 'map',
        'rotate': 180,
        'src': 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/car.png'
      }),
      'start': new TMap.MarkerStyle({
        'width': 25,
        'height': 35,
        'anchor': { x: 16, y: 32 },
        'src': 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png'
      }),
      'end': new TMap.MarkerStyle({
        'width': 25,
        'height': 35,
        'anchor': { x: 16, y: 32 },
        'src': 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png'
      })
    },
    geometries: []
  })

  map.cfg.routeLabel = new TMap.MultiLabel({
    id: 'BusLineRoute-route-label',
    map: map, //设置折线图层显示到哪个地图实例中
    //文字标记样式
    styles: {
      'label': new TMap.LabelStyle({
        'color': '#F24E4E', //颜色属性
        'size': 14, //文字大小属性
        'offset': { x: 0, y: 20 }, //文字偏移属性单位为像素
        'angle': 0, //文字旋转属性
        'alignment': 'center', //文字水平对齐属性
        'verticalAlignment': 'middle' //文字垂直对齐属性
      })
    }
    //文字标记数据
    //geometries: [{
    //    'id': 'label_1', //点图形数据的标志信息
    //    'styleId': 'label', //样式id
    //    'position': center, //标注点位置
    //    'content': '腾讯北京总部', //标注文本
    //}]
  })

  map.cfg.routePolylineLayer = new TMap.MultiPolyline({
    map, // 绘制到目标地图
    // 折线样式定义
    styles: {
      'style_blue': new TMap.PolylineStyle({
        'color': '#3777FF', // 线填充色
        'width': 6, // 折线宽度
        'borderWidth': 3, // 边线宽度
        'borderColor': '#FFF', // 边线颜色
        'lineCap': 'round', // 线端头方式,
        // 显示方向箭头
        'showArrow': true,
        'arrowOptions': {
          'space': 60 // 箭头间隔
        }
      })
    },
    geometries: []
  })
}


// 计算两点之间的距离
export function computeDistance(map, lat1, lon1, lat2, lon2) {
  if (!map || !map.cfg) {
    return -1
  }

  const TMap = map.cfg.TMap
  const distance = TMap.geometry.computeDistance([
    new TMap.LatLng(lat1, lon1),
    new TMap.LatLng(lat2, lon2)
  ])
  return distance
}

// 更新线路轨迹
export function updateRouteLayer (map, carMove, rawPath) {
  if (!map || !map.cfg) {
    return
  }

  const TMap = map.cfg.TMap
  let path = rawPath ? rawPath.map((e) => {
    return new TMap.LatLng(e.Latitude, e.Longitude)
  }) : null
  map.cfg.path = path

  let updateCar = true
  // let movingDistance = 0
  // if (carMove.last != null && carMove.last.Latitude > 0 && carMove.current != null && carMove.current.Latitude > 0) {
  //   movingDistance = TMap.geometry.computeDistance([
  //     new TMap.LatLng(carMove.last.Latitude, carMove.last.Longitude),
  //     new TMap.LatLng(carMove.current.Latitude, carMove.current.Longitude)
  //   ])
  // }

  // if (movingDistance <= 5) {
  //   console.log(`移动幅度${movingDistance}过小，不更新车辆`)
  //   updateCar = false
  // }

  const layer = map.cfg.routeLayer
  const label = map.cfg.routeLabel
  const infoWindow = map.cfg.infoWindow
  // 边界点，用于计算显示区域
  let range = []

  let overlap = map.cfg.overlap
  if (overlap) {
    console.log('overlap destroy')
    overlap.destroy()
  }

  let flash = map.cfg.flash
  if ((flash || {}).latitude !== carMove.mine.Latitude || (flash || {}).longitude !== carMove.mine.Longitude) {
    if (flash) {
      flash.destroy()
      map.cfg.flash = null
    }

    if (carMove.mine.Latitude) {
      // console.log('绘制当前位置')
      // console.log(carMove.mine)
      flash = new TMapFlashMaker({
        map: map,
        content:
          '<div class="flash"><div class="outer"><div class="inner"></div></div></div>',
        position: new TMap.LatLng(carMove.mine.Latitude, carMove.mine.Longitude)
      })
      map.cfg.flash = flash
      flash.latitude = carMove.mine.Latitude
      flash.longitude = carMove.mine.Longitude
      // range.push(new TMap.LatLng(carMove.mine.Latitude, carMove.mine.Longitude))
    }
  }
  
  if ((carMove.mine || {}).Latitude) {
    range.push(new TMap.LatLng(carMove.mine.Latitude, carMove.mine.Longitude))
  }

  if (infoWindow) infoWindow.close()

  // 清除原标记
  // 如果车辆移动幅度过小，则不更新，避免出现车头频繁摆动
  layer.remove(layer.getGeometries().map((e) => {
    // if (e.id === 'car' && updateCar === false) {
    if (e.id === 'car' && carMove.current != null && carMove.current.Latitude > 0) {
      return null
    } else {
      return e.id
    }
  }))

  label.remove(label.getGeometries().map((e) => e.id))

  if (carMove != null) {
    const station = carMove.waitingStation
    if (station != null) {
      range.push(new TMap.LatLng(station.location.lat, station.location.lon))
      layer.add([{
        id: 'station',
        styleId: 'station',
        position: new TMap.LatLng(station.location.lat, station.location.lon)
      }])

      label.add([{
          'id': 'station_label', //点图形数据的标志信息
          'styleId': 'label', //样式id
          'position': new TMap.LatLng(station.location.lat, station.location.lon),
          'content': station.name //标注文本
        }
      ])
    }
  }

  // console.log('update car position')
  // console.log(carMove)
  if (carMove != null && carMove.current && carMove.current.Latitude) {
    let state = carMove.state
    // let progress = carMove.progress
    if (carMove.progress) {
      overlap = new TMapDIVMaker({
        map: map,
        content:
          // '<div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px; margin: 2px; font-size: 4px !important; height: 30px; line-height: 30px; overflow: hidden; width: 100px; border-radius: 120px; background-color: #fff;">' +
          '<div class="tmap_label">' + 
          '<p>' + state + '</p>' +
          '</div>',
        position: new TMap.LatLng(carMove.current.Latitude, carMove.current.Longitude)
      })
    } else {
      overlap = new TMapDIVMaker({
        map: map,
        content: 
        // '<div style="box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px; margin: 2px; font-size: 4px !important; border-radius: 120px; height: 30px; line-height: 30px; overflow: hidden; text-align: center; width: 100px; background-color: #fff;">' +
        '<div class="tmap_label">' +
          '<p>' + state + '</p>' +
        '</div>',
        position: new TMap.LatLng(carMove.current.Latitude, carMove.current.Longitude)
      })
    }

    map.cfg.overlap = overlap

    // 存在上一次位置且当前位置有效
    if (carMove.last != null && carMove.last.Latitude > 0) {
      const points = [
        new TMap.LatLng(carMove.last.Latitude, carMove.last.Longitude),
        new TMap.LatLng(carMove.current.Latitude, carMove.current.Longitude)
      ]
      range = range.concat(points)
      if (updateCar) {
        const carGeometry = layer.getGeometryById('car')
        if (carGeometry === null) {
          // console.log('create carGeometry')
          layer.updateGeometries([{
            id: 'car',
            styleId: 'car-down',
            position: points[0]
          }])
        } else {
          // console.log('carGeometry exists')
        }

        layer.moveAlong({
          'car': {
            path: points,
            speed: 100
          }
        }, {
          autoRotation: true
        })
      }
    } else {
      if (carMove.current.Latitude > 0) {
        range.push(new TMap.LatLng(carMove.current.Latitude, carMove.current.Longitude))
        layer.add([{
          id: 'car',
          styleId: 'car-down',
          position: new TMap.LatLng(carMove.current.Latitude, carMove.current.Longitude),
        }])
      }
    }
  }

  // 空路径
  if (!path || path.length === 0) {
    return
  }

  // layer.add([{
  //   'id': 'start',
  //   'styleId': 'start',
  //   'position': path[0]
  // }, {
  //   'id': 'end',
  //   'styleId': 'end',
  //   'position': path[path.length - 1]
  // }])

  const polylineLayer = map.cfg.routePolylineLayer
  polylineLayer.add([{
    id: 'route',
    styleId: 'style_blue',
    paths: path
  }])

  if (range && range.length > 0) {
    path = path.concat(range)
  }
  let bounds = computeBounds(map, path)
  if (carMove && range && range.length >= 2) {
    bounds = computeBounds(map, range)
  }
  map.fitBounds(bounds, { padding: 70 })
}

// 模拟线路行驶
export function playRoute (map, speed) {
  if (!map || !map.cfg) {
    return
  }

  const layer = map.cfg.routeLayer
  const path = map.cfg.path

  if (path && path.length > 0) {
    layer.moveAlong({
      'car': {
        path,
        speed: speed || 500
      }
    }, {
      autoRotation: true
    })
  }
}


// 根据坐标集调整地图可视区域
export function computeBounds (map, points) {
  if (!map || !map.cfg) {
    return
  }

  const TMap = map.cfg.TMap

  // 创建LatLngBounds实例
  var latlngBounds = new TMap.LatLngBounds()
  // 将坐标逐一做为参数传入extend方法，latlngBounds会根据传入坐标自动扩展生成
  for (var i = 0; i < points.length; i++) {
      latlngBounds.extend(points[i])
  }
  return latlngBounds
}
